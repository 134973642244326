function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { defineComponent } from 'vue';
import I18n from "../../../../../utils/i18n";
import DropdownPanel from "../../../../../components/dropdown/DropdownPanel.vue";
import HueButton from "../../../../../components/HueButton.vue";
import HueLink from "../../../../../components/HueLink.vue";
var SORTABLE_COLUMNS = new Set(['startTime', 'endTime', 'duration', 'cpuTime', 'rowsProduced', 'peakMemory', 'hdfsBytesRead']);
export default defineComponent({
  name: 'SortSelector',
  components: {
    DropdownPanel: DropdownPanel,
    HueButton: HueButton,
    HueLink: HueLink
  },
  props: {
    sort: {
      type: Object,
      required: true
    },
    columns: {
      type: Object,
      required: true
    }
  },
  emits: ['sort-changed'],
  data: function data() {
    return {
      sortableColumns: this.columns.filter(function (column) {
        return SORTABLE_COLUMNS.has(String(column.key));
      }),
      currentSort: this.sort
    };
  },
  computed: {
    applyDisabled: function applyDisabled() {
      return this.currentSort === this.sort;
    },
    label: function label() {
      var _this = this;
      var column = this.columns.find(function (col) {
        return col.key === _this.sort.column;
      });
      return column ? "".concat(column.label, ": ").concat(I18n(this.sort.order === 'ASC' ? 'Asc' : 'Desc')) : '';
    }
  },
  watch: {
    sort: function sort(_sort) {
      this.currentSort = _sort;
    }
  },
  methods: {
    I18n: I18n,
    setColumn: function setColumn(column) {
      this.currentSort = _objectSpread(_objectSpread({}, this.currentSort), {}, {
        column: String(column.key)
      });
    },
    setOrder: function setOrder(asc) {
      this.currentSort = _objectSpread(_objectSpread({}, this.currentSort), {}, {
        order: asc ? 'ASC' : 'DESC'
      });
    },
    cancel: function cancel(closePanel) {
      closePanel();
    },
    apply: function apply(closePanel) {
      this.$emit('sort-changed', this.currentSort);
      closePanel();
    }
  }
});